<template>
  <div class="project-item">
    <div class="project-class-item center">
      <p>{{$t("projectClassItemTitle1")}}</p>
      <div class="project-class-item-pic-box">
        <div class="pic-item">
          <router-link :to="{path:'guazhou-project'}">
            <img src="../../../assets/images/project/img7.png" alt="pic1">
            <div class="project-item-content-title">
              <p>{{$t("projectClassItemProjectTitle1")}}</p>
              <span>{{$t("projectClassItemProjectTime1")}}</span>
            </div>
          </router-link>
        </div>
        <div class="pic-item">
          <img src="../../../assets/images/project/img8.png" alt="pic1">
          <div class="project-item-content-title">
            <p>{{$t("projectClassItemProjectTitle2")}}</p>
            <span>{{$t("projectClassItemProjectTime2")}}</span>
          </div>
        </div>
        <div class="pic-item pic-item-empty">
          <img src="../../../assets/images/project/project-empty.png" alt="pic1">
          <div class="project-item-content-title">
            <img src="../../../assets/images/project/project-empty-no.png" alt="pic1">
            <span>COMING SOON</span>
          </div>
        </div>
      </div>
    </div>
    <div class="project-class-item center">
      <p>{{$t("projectClassItemTitle2")}}</p>
      <div class="project-class-item-pic-box">
        <div class="pic-item">
          <router-link :to="{path:'forestFarmCarbonSinkProject-Jiangxi'}">
            <img src="../../../assets/images/project/img1.png" alt="pic1">
            <div class="project-item-content-title">
              <p>{{$t("projectItemTitle1")}}</p>
              <span>{{$t("projectItemyear1")}}</span>
            </div>
          </router-link>
        </div>
        <div class="pic-item">
          <router-link :to="{path:'afforestation-Fangcheng'}">
          <img src="../../../assets/images/project/img6.png" alt="pic6">
          <div class="project-item-content-title">
            <p>{{$t("projectItemTitle6")}}</p>
            <span>{{$t("projectItemyear6")}}</span>
          </div>
        </router-link>
        </div>
        <div class="pic-item pic-item-empty">
          <img src="../../../assets/images/project/project-empty.png" alt="pic1">
          <div class="project-item-content-title">
            <img src="../../../assets/images/project/project-empty-no.png" alt="pic1">
            <span>COMING SOON</span>
          </div>
        </div>
      </div>
    </div>
    <div class="project-class-item center">
      <p>{{$t("projectClassItemTitle3")}}</p>
      <div class="project-class-item-pic-box">
        <div class="pic-item">
          <router-link :to="{path:'conversionOfLoggedToProtectedForest-InnerMongolia'}">
          <img src="../../../assets/images/project/img2.png" alt="pic2">
          <div class="project-item-content-title">
            <p>{{$t("projectItemTitle7")}}</p>
            <span>{{$t("projectItemyear7")}}</span>
          </div>
        </router-link>
        </div>
        <div class="pic-item">
          <router-link :to="{path:'conversionOfLoggedToProtectedForest-Keyihe'}">
          <img src="../../../assets/images/project/img3.png" alt="pic3">
          <div class="project-item-content-title">
            <p>{{$t("projectItemTitle8")}}</p>
            <span>{{$t("projectItemyear8")}}</span>
          </div>
        </router-link>
        </div>
        <div class="pic-item pic-item-empty">
          <img src="../../../assets/images/project/project-empty.png" alt="pic1">
          <div class="project-item-content-title">
            <img src="../../../assets/images/project/project-empty-no.png" alt="pic1">
            <span>COMING SOON</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "projectItem"
}
</script>
<style>
.project-item {
  width: 100%;
  background: #FFFFFF;
  padding: 50px 0 100px;
}
.project-class-item {
  padding-top: 50px;
}
.project-class-item p {
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 30px;
  color: #333333;
  line-height: 42px;
  text-align: left;
  font-style: normal;
  margin-bottom: 30px;
}
.project-class-item-pic-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.pic-item {
  width: 374px;
  height: 300px;
  position: relative;
  border-radius: 30px;
  overflow: hidden;
}
.pic-item img {
  width: 100%;
  height: 100%;
}
.pic-item li a::before {
  width: 367px;
  height:276px;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 5;
}
.pic-item li:hover img{
  box-shadow: 0px -10px 10px rgba(51, 51, 51, 0.3);
}
.pic-item-empty .project-item-content-title {
  padding: 0 !important;
  text-align: center;
  bottom: 105px;
}
.pic-item-empty .project-item-content-title img{
  width: 69px;
  height: 52px;
  margin: 103px auto 20px;
  display: block;
}
.pic-item-empty .project-item-content-title span{
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 16px;
  color: #3BBA68;
  line-height: 22px;
  letter-spacing: 1px;
  font-style: normal;
}




.project-item-content-title{
  position: absolute;
  width: 100%;
  bottom: 30px;
  left: 0;
  padding-left: 34px;
  z-index: 6;
}
.project-item-content-title p{
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 22px;
  margin: 0;
}
.project-item-content-title span {
  font-size: 15px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 24px;
}
#app.word-spacing .project-item-content-title {
  padding-left: 28px;
}

.project-item .project-item-content{
  height: 100%;
  padding-top: 125px;
  display: flex;
  /* flex-direction: column; */
  flex-wrap: wrap;
}
.project-item .project-item-content li {
  width: 367px;
  height: 306px;
  margin-right: 34px;
  position: relative;
}
.project-item-content li a::before {
  width: 367px;
  height:276px;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 5;
}

.project-item-content li:hover img{
  box-shadow: 0px -10px 10px rgba(51, 51, 51, 0.3);
}
.project-item-content li:nth-child(3n){
  margin-right: 0;
}

</style>