<template>
  <div class="homepage">
    <div class="homepage-header">
      <public-header></public-header>
      <!-- 大标题 -->
      <div class="homepage-title provide-title">
        <!-- <img src="../../assets/images/index/title.png" alt="重视每一个环保承诺"> -->
        <p class="spacing6">{{$t("bannerTitle")}}</p>
      </div>
      <div class="more center">
        <!-- 需要在增加一个路由 -->
        <router-link :to="{path:'/project'}">
          <p class="spacing5">{{$t("moreTitle")}}</p>
        </router-link>
      </div>
      <div class="scoll-icon">
        <img src="../../assets/images/index/scroll.png" alt="icon">
      </div>
    </div>
    <!-- 使命 -->
    <mission />
    <!-- 服务 -->
    <service-new />
    <!-- <service></service> -->
    <!-- 项目 -->
    <project />
    <!-- 消息 -->
    <news />
    <!-- 会员单位 -->
    <member />
    <!-- 合作单位 -->
    <partner />
    <public-footer></public-footer>
  </div>
</template>
<script>
// 旧版本的服务  用于存档
// import service from "./componentes/service.vue";
import mission from "./componentes/mission.vue";
import serviceNew from "./componentes/serviceNew.vue";
import project from "./componentes/project.vue";
import news from "./componentes/news.vue";
import member from "./componentes/member.vue";
import partner from "./componentes/partner.vue";
export default {
  name: 'index',
  components:{
    // service,
    project,
    news,
    member,
    partner,
    mission,
    serviceNew
  },
  data() {
    return {
    }
  },
  created(){
    
  },
  mounted(){
    
  }
}
</script>
<style>
.homepage-header{
  width: 100%;
  min-width: 1200px;
  height: 750px;
  background-image: url(../../assets/images/index/bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.homepage-title{
  margin-top: 283px;
  display: flex;
  justify-content: center;
}
.provide-title {
  margin-top: 283px;
  display: flex;
  justify-content: center;
  font-size: 68px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  line-height: 68px;
}
.provide-title p {
  color: #fff;
  letter-spacing: 6px;
  word-spacing: 3px;
}
.more {
  margin-top: 52px;
  display: flex;
  justify-content: center;
}
.more p{
  width: 190px;
  height: 52px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #fff;
  line-height: 52px;
  letter-spacing: 5px;
  text-align: center;
  background-image: url(../../assets/images/index/Dynamic.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.scoll-icon {
  position: absolute;
  bottom: 38px;
  left: 50%;
  margin-left: -11px;
}

</style>
