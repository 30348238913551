<template>
  <div class="news">
    <div class="news-content center" ref="news">
      <div class="news-content-left">
        <img src="../../../assets/images/index/new.png" alt="消息背景图">
      </div>
      <div class="news-content-right" ref="right">
        <h3>{{$t("newsTitle")}}</h3>
        <p>{{$t("newsContentTitle")}}</p>
        <!-- <span>{{$t("newsDetailed")}}</span> -->
        <a :href="`${$t('ahref')}`" class="spacing5">{{$t("moreTitle")}}</a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "news",
  data() {
    return {
      domWidth:document.documentElement.clientWidth
    }
  },
  watch: {
    domWidth:function(val){
      if(1440 < this.domWidth){
        this.$refs.news.style.width = "1440px";
        this.$refs.right.style.width = "720px";
      }else  if(val <= 1170){
        this.$refs.news.style.width = "1170px";
        this.$refs.right.style.width = "463px";
      } else if(1200 < val < 1440){
        this.$refs.news.style.width = (val - 17) + "px";
        this.$refs.right.style.width = (val - 737) + "px";
      } 
    }
  },
  mounted(){
    window.addEventListener('resize',() => {
      this.domWidth = document.body.offsetWidth;
    })
    if(1440 < this.domWidth){
      this.$refs.news.style.width = "1440px";
      this.$refs.right.style.width = "720px";
    }else if(this.domWidth <= 1170){
      this.$refs.news.style.width = "1170px";
      this.$refs.right.style.width = "463px";
    } else if(1170 < this.domWidth < 1440){
      this.$refs.news.style.width = (this.domWidth - 17) + "px";
      this.$refs.right.style.width = (this.domWidth - 737) + "px";
    }
  }
}
</script>
<style>
.news{
  width:100%;
  height: 632px;
  margin: 0 auto;
  background: #F3F7F8;
}
.news-content{
  width: 1440px;
  height: 100%;
  display: flex;
  justify-content: space-between;
}
.news-content-left,.news-content-right{
  width: 720px;
  height: 100%;;
}
.news-content-right{
  box-sizing: border-box;
  background: #F3F7F8;
  padding: 170px  0 0 116px; 
}
.news-content-right h3{
  font-size: 34px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #1D1D1D;
  line-height: 116px;
}
.news-content-right p {
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 20px;
  margin-bottom: 62px;
}
.news-content-right span {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 24px;
}
.news-content-right a{
  display: block;
  margin-top: 40px;
  cursor: pointer;
  width: 160px;
  height: 52px;
  margin-top: 40px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #fff;
  line-height: 52px;
  letter-spacing: 5px;
  text-align: center;
  cursor: pointer;
  background-image: url(../../../assets/images/index/button.png);
  background-repeat: no-repeat;
  background-size: cover;
}
</style>