<template>
  <div class="mission">
    <div class="center" :class="locale === 'zh-CN'? 'mission-inner-zh' : 'mission-inner-en'">
      <div class="mission-left">
        <h6 v-if="locale !== 'zh-CN'">Our Purpose</h6>
        <div class="mission-left-top">
          <p class="mission-title">{{$t("missionTitle1")}}</p>
          <p class="mission-action">{{$t("missionAction1")}}</p>
        </div>
        <div class="mission-left-bottom">
          <p class="mission-title">{{$t("missionTitle2")}}</p>
          <p class="mission-action">{{$t("missionAction2")}}</p>
        </div>
      </div>
      <div class="mission-right">
        <img src="../../../assets/images/index/mission.png" alt="mission">
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "mission",
  data() {
    return {
      locale: ''
    }
  },
  created() {
    this.locale = localStorage.getItem('locale')
    this.locale = this.locale ? this.locale : 'zh-CN'
  },
}
</script>
<style>
.mission {
  width: 100%;
  background: #FFFFFF;
}
.mission-inner-zh, .mission-inner-en {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 96px 0;
}
.mission-inner-zh .mission-right {
  width: 860px;
  height: 648px;
}
.mission-right img {
  width: 100%;
  height: 100%;
}
.mission-inner-zh .mission-left {
  color: #333333;
  width: 270px;
  padding-top: 60px;
}

.mission-left .mission-title {
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 36px;
  line-height: 50px;
  font-style: normal;
  display: inline-block;
  position: relative;
}
.mission-left .mission-title::after {
  display: inline-block;
  content: '';
  width: 100%;
  height: 5px;
  background: linear-gradient(to bottom, #626262, #333 );
  position: absolute;
  top: 50px;
  left: 0;
}
.mission-left .mission-action {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 24px;
  line-height: 40px;
  font-style: normal;
  margin-top: 5px;
}
.mission-left-bottom {
  margin-top: 50px;
}
.mission-inner-en .mission-right {
  width: 730px;
  height: 716px;
}
.mission-inner-en .mission-left {
  width: 398px;
  margin-top: -32px;
}
.mission-inner-en .mission-left h6 {
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 100px;
  color: #333333;
  line-height: 140px;
  letter-spacing: 1px;
  font-style: normal;
  margin-bottom: 50px;
}
.mission-inner-en .mission-left .mission-action {
  font-size: 18px;
  line-height: 30px;
}

</style>