<template>
  <div class="grassland" :class="locale !== 'zh-CN' ? 'grassland-en' : ''">
    <div class="grassland-header">
      <public-header></public-header>
      <!-- 大标题 -->
      <div class="grassland-title">
        <p class="spacing6">{{$t("grasslandTitle")}}</p>
      </div>
      <div class="scoll-icon">
        <img src="../../assets/images/index/scroll.png" alt="icon">
      </div>
    </div>
    <div class="center sustainable">
      <p class="sub-title">{{$t("sustainableTitle")}}</p>
      <div class="sustainable-box">
        <div class="sustainable-content">
        <p>{{$t("sustainableContent1")}}</p>
        <p>{{$t("sustainableContent2")}}</p>
        <p >{{$t("sustainableContent3")}}</p>
        <ul>
          <li><i></i>{{$t("sustainableContentli1")}}</li>
          <li><i></i>{{$t("sustainableContentli2")}}</li>
          <li><i></i>{{$t("sustainableContentli3")}}</li>
          <li><i></i>{{$t("sustainableContentli4")}}</li>
        </ul>
      </div>
      <div class="sustainable-pic">
        <img v-if="locale !== 'zh-CN'" src="../../assets/images/grassland/vcs-en.png" alt="">
        <img v-else src="../../assets/images/grassland/vcs-zh.png" alt="">
        <p>{{$t("sustainablePicOrigin")}}</p>
      </div>
      </div>
    </div>
    <div class="center carbon">
      <p class="sub-title">{{$t("grasslandCarbonTitle")}}</p>
      <div class="sustainable-content clear-margin-right">
        <p>{{$t("grasslandCarbonContent1")}}</p>
        <p>{{$t("grasslandCarbonContent2")}}</p>
        <p class="margin-bottom">{{$t("grasslandCarbonContent3")}}</p>
        <ul class="margin-bottom">
          <li><i></i>{{$t("carbonContentli1")}}</li>
          <li><i></i>{{$t("carbonContentli2")}}</li>
          <li><i></i>{{$t("carbonContentli3")}}</li>
          <li><i></i>{{$t("carbonContentli4")}}</li>
          <li ><i></i>{{$t("carbonContentli5")}}</li>
        </ul>
        <p>{{$t("carbonContent4")}}</p>
        <div class="carbon-legend">
          <div class="legend-left">
            <p class="legend-title">{{$t("carbonLegend1Title")}}</p>
            <p class="legend-content">{{$t("carbonLegend1Content1")}}</p>
            <p class="legend-content">{{$t("carbonLegend1Content2")}}</p>
            <img class="legend-pic" src="../../assets/images/grassland/card1.png" alt="" />
          </div>
          <img class="legend-add" src="../../assets/images/grassland/add.png" alt="" />
          <div class="legend-right">
            <p class="legend-title">{{$t("carbonLegend2Title")}}</p>
            <p class="legend-content">{{$t("carbonLegend2Content1")}}</p>
            <p class="legend-content">{{$t("carbonLegend2Content2")}}</p>
            <p class="legend-content">{{$t("carbonLegend2Content3")}}</p>
            <img class="legend-pic" src="../../assets/images/grassland/card2.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="center verification">
      <p class="sub-title">{{$t("verificationTitle")}}</p>
      <div class="sustainable-content clear-margin-right">
        <p>{{$t("verificationContent")}}</p>
        <ul>
          <li><i></i>{{$t("verificationItem1")}}</li>
          <li><i></i>{{$t("verificationItem2")}}</li>
          <li><i></i>{{$t("verificationItem3")}}</li>
          <li><i></i>{{$t("verificationItem4")}}</li>
          <li ><i></i>{{$t("verificationItem5")}}</li>
          <li ><i></i>{{$t("verificationItem6")}}</li>
        </ul>
      </div>
    </div>
    <div class="center contribution clear-box-shadow">
      <p class="sub-title">{{$t("contributionTitle")}}</p>
      <el-carousel arrow="always" class="grassland-carousel" :class="locale !== 'zh-CN' ? 'grassland-carousel-en' : ''" height="555px">
        <el-carousel-item>
          <div class="carousel-copywrit">
            <img src="../../assets/images/grassland/banner1.png" alt="">
            <div class="carousel-copywrit-inner">
              <p class="carousel-title">{{$t("carouselTitle1")}}</p>
              <p class="carousel-content">{{$t("carouselContent1")}}</p>
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="carousel-copywrit">
            <img src="../../assets/images/grassland/banner3.png" alt="">
            <div class="carousel-copywrit-inner">
              <p class="carousel-title">{{$t("carouselTitle2")}}</p>
              <p class="carousel-content">{{$t("carouselContent2")}}</p>
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="carousel-copywrit">
            <img src="../../assets/images/grassland/banner2.png" alt="">
            <div class="carousel-copywrit-inner">
              <p class="carousel-title">{{$t("carouselTitle3")}}</p>
              <p class="carousel-content">{{$t("carouselContent3")}}</p>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
      <div class="reference">
        <p>Reference: </p>
        <p>
          [1] Dondini, M., Martin, M., De Camillis, C., Uwizeye, A., Soussana, J. F., Robinson, T. & Steinfeld, H. 2023. Global assessment of soil carbon in grasslands – From current stock estimates to sequestration potential. FAO Animal Production and Health Paper No. 187. Rome, FAO. https://doi.org/10.4060/cc3981en
        </p>
        <p>[2] Yue Li, Hongming, D., Timm, T., Shiping, W., Benjamin, H., Leslie, L., Neil, B. 2021. Approved VCS Methodology  VM0026: Sustainable Grassland Management. FAO. https://verra.org/methodologies/vm0026-methodology-for-sustainable-grassland-management-sgm-v1-0/</p>
        <p>[3] Krista, E., Jessalyn, B., Hector, M., Krista, E., Anna, D. 2022. The Lasting Effects of Overgrazing on Rangeland Ecosystems. South Dakota State University Extension. https://extension.sdstate.edu/lasting-effects-overgrazing-rangeland-ecosystems</p>
      </div>
    </div>
    <div class="center acceptance clear-box-shadow">
      <p class="sub-title">{{$t("acceptanceTitle")}}</p>
      <div class="acceptance-box">
        <div class="acceptance-documents">
            <div>
              <p>{{$t("acceptanceSubTitle")}}</p>
              <router-link :to="{path: '/project'}">
                {{$t("acceptanceBUtton")}}
              </router-link>
            </div>
        </div>

      </div>
    </div>
    <public-footer></public-footer>
  </div>
</template>
<script>
export default {
  name: 'certificate',
  data() {
    return {
      locale: ''
    }
  },
  created() {
    this.locale = localStorage.getItem('locale')
    this.locale = this.locale ? this.locale : 'zh-CN'
  },
}
</script>
<style>
.grassland-header{
  width: 100%;
  min-width: 1200px;
  height: 750px;
  background-image: url(../../assets/images/grassland/banner.png);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.grassland-title{
  margin-top: 283px;
  display: flex;
  justify-content: center;
  font-size: 68px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  line-height: 68px;
}
.scoll-icon {
  position: absolute;
  bottom: 38px;
  left: 50%;
  margin-left: -11px;
}
.grassland-title p {
  color: #FFFFFF;
}
.grassland .sustainable, .grassland .carbon, .grassland .verification, .grassland .contribution, .grassland .acceptance {
  box-sizing: border-box;
  padding: 54px;
  box-shadow: 0px 0px 4px 0px rgba(161, 208, 107, 0.80);
  margin-top: 96px;
  border-radius: 30px;
}
.sustainable .sustainable-box {
  display: flex;
  flex-direction: row;
}
.sub-title {
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 30px;
  color: #333333;
  line-height: 42px;
  text-align: left;
  font-style: normal;
  margin-bottom: 30px;
}
.sustainable-content {
  margin-right: 50px;
}
.sustainable-content p {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 16px;
  color: #333333;
  line-height: 30px;
  text-align: left;
  font-style: normal;
  margin-bottom: 36px;
}
.sustainable-content p:last-child {
  margin-bottom: 0;
}
.sustainable-content ul li {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 16px;
  color: #333333;
  line-height: 30px;
  text-align: left;
  font-style: normal;
  padding-left: 20px;
  position: relative;
}
.sustainable-content ul {
  padding-left: 40px;
}
.sustainable-content ul li i{
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #333;
  position: absolute;
  top: 0;
  top: 10px;
  left: 0;
}
.sustainable-pic img {
  width: 386px;
  height: 430px;
  margin-bottom: 20px;

}
.sustainable-pic p {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 16px;
  color: #666666;
  line-height: 30px;
  text-align: center;
  font-style: normal;
}
.center .margin-bottom {
  margin-bottom: 20px !important;
}
.center .clear-margin-right {
  margin-right: 0!important;
}
.carbon-legend {
  margin-top: 36px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.carbon-legend div{
  box-sizing: border-box;
  width: 498px;
  height: 616px;
  padding: 54px 44px 47px;
  border-radius: 30px;
  background-image:url(../../assets/images/grassland/legend.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
} 
.legend-add {
  display: block;
  width: 60px;
  height: 60px;
  position: relative;
  top: 278px;
}


.carbon-legend .legend-title {
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 36px;
  color: #FFFFFF;
  line-height: 50px;
  text-align: center;
  font-style: normal;
  margin-bottom: 14px;
}
.carbon-legend .legend-content {
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 18px;
  color: #FFFFFF;
  line-height: 34px;
  text-align: center;
  font-style: normal;
  margin-bottom: 0;
  
}
.carbon-legend .legend-pic {
  position: absolute;
  bottom: 47px;
  width: 410px;
  height: 292px;
}
.contribution {
  margin-top: 42px;
}
/* .verification .sustainable-content li {
  text-indent: -20px;
  padding-left: 20px;
} */
.grassland-carousel .carousel-title, .grassland-carousel .carousel-content {
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 59px;
  text-align: center;
  font-style: normal;
}
.grassland-carousel {
  border-radius: 30px;
}

.grassland-carousel .el-carousel__indicators {
  display: none;
}

.grassland-carousel .carousel-title {
  font-size: 42px;
 
}
.grassland-carousel .carousel-content {
  font-size: 36px;
}
.grassland-carousel .el-icon-arrow-left, .grassland-carousel .el-icon-arrow-right {
  display: none !important;
}
.grassland-carousel .el-carousel__arrow--left,
.grassland-carousel .el-carousel__arrow--right {
  width: 28px;
  height: 50px;
  background-size: 100% 100% !important;
}
.grassland-carousel .el-carousel__arrow--left {
  background: url(../../assets/images/grassland/left.png) no-repeat;
  
}
.grassland-carousel .el-carousel__arrow--right {
  background: url(../../assets/images/grassland/right.png) no-repeat;
}
.grassland-carousel .carousel-copywrit {
  position: relative;
}
.grassland-carousel .carousel-copywrit img {
  width: 100%;
  height: 100%;
}
.grassland-carousel .carousel-copywrit-inner {
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 600px;
  left: 50%;
  margin-left: -300px;
}
.grassland-carousel-en .carousel-copywrit-inner {
  width: 890px;
  margin-left: -445px;
}
.reference {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 12px;
  color: #666666;
  line-height: 24px;
  text-align: justify;
  font-style: normal;
  margin-top: 30px;
}
.clear-box-shadow {
  box-shadow: none !important;
}
.acceptance {
  margin-top: -79px;
  margin-bottom: 42px;
}
.acceptance-box {
  border-radius: 30px;
  width: 100%;
  height: 544px;
  background-image: url(../../assets/images/grassland/button-bg.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
}
.acceptance-documents {
  position: absolute;
  width: 50%;
  height: 100%;
}
.acceptance-documents div {
  position: absolute;
  width: 220px;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.acceptance-documents div p {
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 24px;
  color: #333333;
  line-height: 30px;
  text-align: center;
  font-style: normal;
  margin-bottom: 35px;
}
.acceptance-documents div a {
  text-align: center;
  display: block;
  width: 180px;
  height: 82px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 21px;
  color: #FFFFFF;
  line-height: 82px;
  text-align: center;
  font-style: normal;
  background-image: url(../../assets/images/grassland/button.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin-left: 20px;
  cursor: pointer;
}

.grassland-en .grassland-title p{
  width: 1200px;
  text-align: center;
}
.grassland-en .sustainable-pic img {
  width: 392px;
  height: 778px;
}
</style>
