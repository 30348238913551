<template>
   <div class="about-us">
    <div class="about-us-header">
      <public-header></public-header>
      <!-- 大标题 -->
      <div class="about-us-title">
        <p class="spacing6">{{$t("aboutUs")}}</p>
      </div>
      <div class="scoll-icon">
        <img src="../../assets/images/index/scroll.png" alt="icon">
      </div>
    </div>
    <introduce></introduce>
    <div class="development">
      <img v-if="locale === 'en-US' " src="../../assets/images/about/development-en.png" alt="历程" />
      <img v-else src="../../assets/images/about/development-zh.png" alt="历程" />
    </div>
    <my-team></my-team>
    <info />
    <public-footer></public-footer>
  </div>
</template>
<script>
import introduce from "./components/introduce.vue";
import myTeam from "./components/myTeam.vue";
import info from "../../components/info/index.vue"
export default {
  name: "aboutUs",
  components:{
    "introduce": introduce,
    "my-team": myTeam,
    info,
  },
  data() {
    return {
      locale: ''
    }
  },
  created() {
    this.locale = localStorage.getItem('locale')
    this.locale = this.locale ? this.locale : 'zh-CN'
  },
}
</script>
<style>
.about-us {
  background: #fff;
}
.about-us-header{
  width: 100%;
  min-width: 1200px;
  height: 750px;
  background-image: url(../../assets/images/about/bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.about-us-title{
  margin-top: 283px;
  display: flex;
  justify-content: center;
  font-size: 68px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  line-height: 68px;
}
.scoll-icon {
  position: absolute;
  bottom: 38px;
  left: 50%;
  margin-left: -11px;
}
.about-us-title p {
  color: #FFFFFF;
}
.development {
  margin-top: 94px;
}
.development img {
  margin: 0 auto;
  display: block;
}
</style>