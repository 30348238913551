<template>
  <div class="member">
    <div class="member-content center">
      <h3>{{$t("memberOrganizations")}}</h3>
      <ul class="img">
        <li>
          <a href="https://www.ieta.org/"><img src="../../../assets/images/index/pic1.png" alt="pic1"></a>
        </li>
        <li>
          <a href="http://www.cecaweb.org.cn/"><img src="../../../assets/images/index/pic2.png" alt="pic1"></a>
        </li>
        <li>
          <a href="http://www.acet-ceca.com/"><img src="../../../assets/images/index/pic3.png" alt="pic1"></a>
        </li>
        <li>
          <a href="https://climatechaincoalition.org/"><img src="../../../assets/images/index/pic4.png" alt="pic1"></a>
        </li>
        <!-- <li>
          <a href="https://inatba.org/"><img src="../../../assets/images/index/pic5.png" alt="pic1"></a>
        </li> -->
        <li>
          <a href="https://www.geidco.org.cn/"><img src="../../../assets/images/index/pic6.png" alt="pic1"></a>
        </li>
        <li>
          <a href="https://www.cbeex.com.cn/"><img src="../../../assets/images/index/pic7.png" alt="pic1"></a>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: "member",
  data() {
    return {

    }
  }
}
</script>
<style>
.member{
  width: 100%;
  height: 495px;
  background: #FFFFFF;
}
.member-content {
  box-sizing: border-box;
  height: 100%;
  padding: 106px 0 0;
}
.member-content h3 {
  font-size: 34px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #1D1D1D;
  line-height: 40px;
}
.member-content .img{
  display: flex;
  flex-wrap: wrap;
  padding-top: 20px;
}
.member-content .img li{
  align-items: flex-start;
  height: 120px;
  line-height: 120px;
  text-align: center;
  margin-right: 134px;
}
.member-content .img li:nth-child(4n){
  margin-right: 0;
}
.member-content .img li:last-child {
  margin-left: -50px;
}

</style>