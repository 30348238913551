<template>
  <div class="service">
    <div class="service-content center">
     <p>{{$t("servieceNewTitle")}}</p>
      <ul class="service-pic-box">
        <li @click="routerLink(1)">
          <img src="../../../assets/images/index/service-pic1.png" alt="service">
          <p>{{$t("servieceNewPic1")}}</p>
        </li>
        <li @click="routerLink(2)">
          <img src="../../../assets/images/index/service-pic2.png" alt="service">
          <p>{{$t("servieceNewPic2")}}</p>
        </li>
        <li @click="routerLink(3)">
          <img src="../../../assets/images/index/service-pic3.png" alt="service">
          <p>{{$t("servieceNewPic3")}}</p>
        </li>
      </ul>
      <div class="service-btn">
        <router-link :to="{path:'/provideService'}">
          <span class="spacing5">{{$t("moreTitle")}}</span>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "service",
  methods: {
    routerLink(ev) {
      switch(ev) {
        case 1:
          this.$router.push('/carbon');
          break;
        case 2:
          this.$router.push('/asset-trad');
          break;
        case 3:
          this.$router.push('/corporate-climate-commitment');
          break;
      }
    }
  }
}
</script>
<style>
/* 服务 */
.service {
  width: 100%;
  height: 686px;
  background: #F3F7F8;
}
.service-content{
  padding: 100px 0;
  box-sizing: border-box;
  height: 100%;
}
.service-content p{
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 33px;
  color: #1D1D1D;
  line-height: 46px;
  text-align: left;
  font-style: normal;
  margin-bottom: 26px;
}
.service-pic-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.service-pic-box li {
  width: 378px;
  height: 316px;
  border-radius: 30px;
  box-shadow: 0px 0px 4px 0px rgba(161, 208, 107, 0.50);
  border-radius: 30px;
  cursor: pointer;
}
.service-pic-box li img {
  width: 378px;
  height: 260px;
}
.service-pic-box li p {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 20px;
  color: #333333;
  line-height: 56px;
  text-align: left;
  font-style: normal;
  text-align: center;
}
.service-content .service-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.service-content .service-btn span {
  display: inline-block;
  width: 160px;
  height: 52px;
  margin-top: 46px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #fff;
  line-height: 52px;
  letter-spacing: 5px;
  text-align: center;
  cursor: pointer;
  background-image: url(../../../assets/images/index/button.png);
  background-repeat: no-repeat;
  background-size: cover;
 }
</style>