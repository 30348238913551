<template>
  <div class="team" :class="locale === 'zh-CN' ? '' : 'team-en'">
    <div class="team-content center">
      <div class="team-list">
        <div class="team-item">
          <img src="../../../assets/images/about/character1.png" alt="hao" />
          <p>{{$t("teamItem1")}}</p>
          <span>{{$t("teamItemPost1")}} </span>
          <button @click="routerJump(1)">
            {{$t("moreTitle")}}
          </button>
        </div>
        <div class="team-item">
          <img src="../../../assets/images/about/character2.png" alt="hao" />
          <p>{{$t("teamItem2")}}</p>
          <span>{{$t("teamItemPost2")}}</span>
          <button @click="routerJump(2)">
            {{$t("moreTitle")}}
          </button>
        </div>
        <div class="team-item">
          <img src="../../../assets/images/about/character3.png" alt="hao" />
          <p>{{$t("teamItem3")}}{{$t("teamItem31")}}</p>
          <span>{{$t("teamItemPost3")}}</span>
          <button @click="routerJump(3)">
            {{$t("moreTitle")}}
          </button>
        </div>
      </div>
      <div class="team-list">
        <div class="team-item">
          <img src="../../../assets/images/about/character4.png" alt="hao" />
          <p>{{$t("teamItem4")}}</p>
          <span>{{$t("teamItemPost4")}}</span>
          <button @click="routerJump(4)">
            {{$t("moreTitle")}}
          </button>
        </div>
        <div class="team-item">
          <img src="../../../assets/images/about/character5.png" alt="hao" />
          <p>{{$t("teamItem5")}}</p>
          <span>{{$t("teamItemPost5")}}</span>
          <button @click="routerJump(5)">
            {{$t("moreTitle")}}
          </button>
        </div>
        <div class="team-item">
          <img src="../../../assets/images/about/character6.png" alt="hao" />
          <p>{{$t("teamItem6")}}</p>
          <span>{{$t("teamItemPost6")}}</span>
          <button>
            {{$t("moreTitle")}}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'myTeam',
  data() {
    return {
      locale: ''
    }
  },
  created() {
    this.locale = localStorage.getItem('locale')
    this.locale = this.locale ? this.locale : 'zh-CN'
  },
  methods: {
    routerJump(index){
      switch (index) {
        case 1:
          this.$router.push('/CarlHao');
          break;
        case 2:
          this.$router.push('/NeoYuyangLin');
          break;
        case 3:
          this.$router.push('/NicholasManthey');
          break;
        case 4:
          this.$router.push('/BeibeiSong');
          break;
        case 5:
          this.$router.push('/RyanChen');
          break;
      }
    }
  }
}
</script>
<style>
.team{
  width: 100%;
  height: 1142px;
  background: #FFFFFF;
}
.team-content{
  height: 100px;
  padding-top: 60px;
}
.team-list {
 display: flex;
 flex-direction: row;
 justify-content: space-between;
 margin-bottom: 66px;
}
.team-list .team-item {
  width: 352px;
  height: 463px;
  padding-top: 54px;
  box-shadow: 0px 0px 4px 0px rgba(161, 208, 107, 0.80);
  border-radius: 30px;

}
.team-list .team-item img {
  display: block;
  margin: 0 auto 30px;
  width: 170px;
  height: 170px;
}
.team-list .team-item p {
  margin-bottom: 13px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 24px;
  color: #333333;
  line-height: 33px;
  text-align: center;
  font-style: normal;
}
.team-list .team-item span {
  display: block;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 16px;
  color: #999999;
  line-height: 22px;
  text-align: center;
  font-style: normal;
  margin-bottom: 30px;
}
.team-list .team-item button {
  width: 106px;
  height: 36px;
  font-size: 14px;
  color: #19A15F;
  line-height: 34px;
  text-align: center;
  font-style: normal;
  border-width: 0px;
  background: transparent;
  border-color: transparent;
  background-image: url(../../../assets/images/about/biankuang.png);
  background-repeat: no-repeat;
  margin-left: 122px;
  cursor: pointer;
}
.team-en .team-list .team-item button {
  width: 110px;
  height: 40px;
  line-height: 40px;
  margin-left: 120px;
  background-size: 100% 100%;
}


</style>