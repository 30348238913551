<template>
  <div class="provide">
    <div class="provide-header">
      <public-header></public-header>
      <!-- 大标题 -->
      <div class="provide-title">
        <p class="spacing6">{{$t("serviceTitle")}}</p>
      </div>
      
      <div class="scoll-icon">
        <img src="../../assets/images/index/scroll.png" alt="icon">
      </div>
    </div>
    <div class="provide-project center">
      <div class="provide-project-item">
        <img src="../../assets/images/provide/pic1.png" alt="icon">
        <div class="project-item-content">
          <p>{{ $t("provideProjectItemTitle1") }}</p>
          <span :class="locale === 'zh-CN'? '' : 'span-line-height-en'">{{ $t("provideProjectItemContent1") }}</span>
          <div class="more-link">
            <router-link :to="{path:'/carbon'}">
              <span class="spacing5">{{$t("moreTitle")}}</span>
            </router-link>
          </div>
        </div>
      </div>
      <div class="provide-project-item">
        
        <div class="project-item-content">
          <p>{{ $t("provideProjectItemTitle2") }}</p>
          <span>{{ $t("provideProjectItemContent2") }}</span>
          <div class="more-link">
            <router-link :to="{path:'/asset-trad'}">
              <span class="spacing5">{{$t("moreTitle")}}</span>
            </router-link>
          </div>
        </div>
        <img src="../../assets/images/provide/pic2.png" alt="icon">
      </div>
      <div class="provide-project-item">
        <img src="../../assets/images/provide/pic3.png" alt="icon">
        <div class="project-item-content">
          <p style="width: 270px;">{{ $t("provideProjectItemTitle3") }}</p>
          <span>{{ $t("provideProjectItemContent3") }}</span>
          <div class="more-link">
            <router-link :to="{path:'/corporate-climate-commitment'}">
              <span class="spacing5">{{$t("moreTitle")}}</span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <public-footer></public-footer>
  </div>
</template>
<script>
export default {
  name:"provideService",
  data() {
    return {
      locale: ''
    }
  },
  created() {
    this.locale = localStorage.getItem('locale')
    this.locale = this.locale ? this.locale : 'zh-CN'
  },
}
</script>
<style>
.provide-header{
  width: 100%;
  min-width: 1200px;
  height: 750px;
  background-image: url(../../assets/images/provide/intro.png);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.provide-title{
  margin-top: 283px;
  display: flex;
  justify-content: center;
  font-size: 68px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  line-height: 68px;
}
.scoll-icon {
  position: absolute;
  bottom: 38px;
  left: 50%;
  margin-left: -11px;
}
.provide-title p {
  color: #FFFFFF;
}
.provide-project {
 height: auto;
 padding: 96px 0;
}
.provide-project-item {
  margin-bottom: 100px;
  display: flex;
  flex-direction: row;
  justify-content: start;
}
.provide-project-item img {
 width: 778px;
 height: 378px;
 margin-right: 48px;
}
.provide-project-item .project-item-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 224px;
  padding: 0 !important;
}
.provide-project-item .project-item-content p{
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 30px;
  color: #333333;
  line-height: 42px;
  text-align: left;
  font-style: normal;
  margin-bottom: 20px;
}
.provide-project-item .project-item-content span{
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 16px;
  color: #333333;
  line-height: 30px;
  text-align: left;
  font-style: normal;
  margin-bottom: 20px;
}
.provide-project-item .project-item-content .span-line-height-en {
  line-height: 22px;
}
.provide-project-item:last-child {
  margin-bottom: 0;
}
.provide-project-item:nth-child(2n) {
  justify-content: end;
}
.provide-project-item:nth-child(2n) img {
  margin: 0 0 0 48px;
}
.project-item-content .more-link span{
  display: inline-block;
  width: 160px;
  height: 52px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #fff;
  line-height: 52px;
  letter-spacing: 5px;
  text-align: center;
  cursor: pointer;
  background-image: url(../../assets/images/index/button.png);
  background-repeat: no-repeat;
  background-size: cover;
}
</style>