<template>
  <div class="certificate">
    <div class="certificate-header">
      <public-header></public-header>
      <!-- 大标题 -->
      <div class="certificate-title">
        <p class="spacing6">{{$t("certificateTitle")}}</p>
      </div>
      <div class="scoll-icon">
        <img src="../../assets/images/index/scroll.png" alt="icon">
      </div>
    </div>
    <div class="certificate-content center" :class="locale !== 'zh-CN' ? 'certificate-content-en' : ''">
      <p class="content-title">{{$t("certificateContentTitle1")}}</p>
      <p class="content-explain">{{$t("certificateContentExplain11")}}</p>
      <p class="content-explain" :class="locale !== 'zh-CN' ? '' : 'margin-bottom-50'" >{{$t("certificateContentExplain12")}}</p>
      <template v-if="locale !== 'zh-CN'">
        <p class="content-explain margin-bottom-50">{{$t("certificateContentExplain13")}}</p>
      </template>
      <p class="content-title">{{$t("certificateContentTitle2")}}</p>
      <p class="content-explain">{{$t("certificateContentExplain21")}}</p>
      <p class="content-explain margin-bottom-50">{{$t("certificateContentExplain22")}}</p>

      <p class="content-title">{{$t("certificateContentTitle3")}}</p>
      <p class="content-explain margin-bottom-30">{{$t("certificateContentExplain3")}}</p>
      <p class="explain-sub-title">{{$t("certificateContentExplain3Sub1")}}</p>
      <p class="explain-sub-explain">{{$t("certificateContentExplain3Sub2")}}</p>
      <img class="explain-map" src="../../assets/images/certificate/map.png" alt="icon">
      <a class="map-origin">{{$t("certificateContentExplain3Origin")}}</a>
      <p class="explain-sub-explain">{{$t("certificateContentExplain3Sub4")}}</p>
      <p class="explain-sub-title">{{$t("certificateContentExplain3Sub5Title")}}</p>  
      <p class="explain-sub-explain" style="margin: 0">{{$t("certificateContentExplain3Sub51")}}</p>
      <p class="explain-sub-explain margin-max-num">{{$t("certificateContentExplain3Sub52")}}</p>
      <p class="content-title">{{$t("certificateContentTitle4")}}</p>
      <img v-if="locale === 'zh-CN'" class="explain-eac" src="../../assets/images/certificate/model-zh.png" alt="icon">
      <img v-else class="explain-eac" src="../../assets/images/certificate/model-en.png" alt="icon">
      <p class="eac-content">{{$t("certificateContentExplain4")}}</p>
      <ul class="eac-content-model">
        <li>{{$t("certificateContentExplainSub1")}}</li>
        <li>{{$t("certificateContentExplainSub2")}}</li>
        <li>{{$t("certificateContentExplainSub3")}}</li>
        <li>{{$t("certificateContentExplainSub4")}}</li>
      </ul>
      <img class="explain-eac" src="../../assets/images/certificate/info-zh.png" alt="icon">
      <p class="eac-origin">{{$t("certificateContent4Origin")}}</p>
      <p class="eac-sub-title" @click="openAnswer('show1')">
        <img v-show="!eacSubAnswer.show1" class="explain-eac none-show-pic" src="../../assets/images/certificate/title-icon-none.png" alt="icon" />
        <img v-show="eacSubAnswer.show1" class="explain-eac" src="../../assets/images/certificate/title-icon.png" alt="icon" />
        {{$t("certificateContent4SubTitle1")}}
      </p>
      <ul class="eac-content-model" v-show="eacSubAnswer.show1">
        <li>{{$t("certificateContent4SubContent1")}}</li>
        <li>{{$t("certificateContent4SubContent2")}}</li>
        <li style="text-indent: 0;">{{$t("certificateContent4SubContent3")}}</li>
        <li style="text-indent: 0;">{{$t("certificateContent4SubContent4")}}</li>
        <li>{{$t("certificateContent4SubContent5")}}</li>
        <li>{{$t("certificateContent4SubContent6")}}</li>
      </ul>
      <p class="eac-sub-title" @click="openAnswer('show2')">
        <img v-show="!eacSubAnswer.show2" class="explain-eac none-show-pic" src="../../assets/images/certificate/title-icon-none.png" alt="icon" />
        <img v-show="eacSubAnswer.show2" class="explain-eac" src="../../assets/images/certificate/title-icon.png" alt="icon" />
        {{$t("certificateContent4SubTitle2")}}
      </p>
      <ul class="eac-content-model clear-text-indent" v-show="eacSubAnswer.show2">
        <li>{{$t("certificateContent4SubContent7")}}</li>
        <li>{{$t("certificateContent4SubContent8")}}</li>
      </ul>
        <p class="eac-sub-title" @click="openAnswer('show3')">
        <img v-show="!eacSubAnswer.show3" class="explain-eac none-show-pic" src="../../assets/images/certificate/title-icon-none.png" alt="icon" />
        <img v-show="eacSubAnswer.show3" class="explain-eac" src="../../assets/images/certificate/title-icon.png" alt="icon" />
        {{$t("certificateContent4SubTitle3")}}
      </p>
      <ul class="eac-content-model clear-text-indent" v-show="eacSubAnswer.show3">
        <li>{{$t("certificateContent4SubContent9")}}</li>
        <li>{{$t("certificateContent4SubContent10")}}</li>
        <template v-if="locale !== 'zh-CN'">
          <li>{{$t("certificateContent4SubContent11")}}</li>
          <li>{{$t("certificateContent4SubContent12")}}</li>
        </template>
      </ul>
      <template v-if="eacSubAnswer.show3">
        <img v-if="locale === 'zh-CN'" class="eac-table" src="../../assets/images/certificate/table-zh.png" alt="icon">
        <img v-else class="eac-table" src="../../assets/images/certificate/table-en.png" alt="icon">
      </template>
      
    </div>
    <info />
    <public-footer></public-footer>
  </div>
</template>
<script>
import info from "../../components/info/index.vue"
export default {
  name: 'certificate',
  components: {
    info,
  },
  data() {
    return {
      locale: '',
      eacSubAnswer: {
        show1: false,
        show2: false,
        show3: false
      }
    }
  },
  created() {
    this.locale = localStorage.getItem('locale')
    this.locale = this.locale ? this.locale : 'zh-CN'
  },
  methods: {
    openAnswer(str) {
      this.eacSubAnswer[str] = !this.eacSubAnswer[str]
    }
  }
}
</script>
<style>
.certificate {
  background: #fff;
}
.certificate-header{
  width: 100%;
  height: 750px;
  background-image: url(../../assets/images/certificate/banner.png);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.certificate-title{
  margin-top: 283px;
  display: flex;
  justify-content: center;
  font-size: 68px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  line-height: 68px;
}
.scoll-icon {
  position: absolute;
  bottom: 38px;
  left: 50%;
  margin-left: -11px;
}
.certificate-title p {
  color: #FFFFFF;
}

.certificate-content {
  box-sizing: border-box;
  padding: 54px 34px 50px;
  box-shadow: 0px 0px 4px 0px rgba(161, 208, 107, 0.80);
  margin-bottom: 96px;
  border-radius: 30px;
  margin-top: 86px;
  background: #fff;
}
.certificate-content .content-title {
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 30px;
  color: #333333;
  line-height: 42px;
  text-align: left;
  font-style: normal;
  margin-bottom: 30px;
  padding-left: 18px;
}
.certificate-content-en .content-title {
  margin-bottom: 20px;
}
.certificate-content .content-explain {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 16px;
  color: #333333;
  line-height: 30px;
  text-align: justify;
  font-style: normal;
  padding-left: 18px;

}
.margin-bottom-50 {
  margin-bottom: 50px;
}
.margin-bottom-30 {
  margin-bottom: 30px;
}
.explain-sub-explain {
  font-family: PingFangSC, PingFang SC;
  font-weight: 300;
  font-size: 16px;
  color: #333333;
  line-height: 22px;
  text-align: left;
  font-style: normal;
  margin-bottom: 30px;
  padding: 0 20px 0 30px;
}

.certificate .explain-sub-title {
  margin-bottom: 10px;
  padding: 0 20px 0 30px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 16px;
  color: #333333;
  line-height: 22px;
  text-align: left;
  font-style: normal;
}
.certificate .explain-sub-title:first-child {
  margin-top: -20px;
}
.explain-map, .explain-eac {
  width: 100%;
  height: 576px;
}
.map-origin, .eac-origin {
  display: block;
  margin-top: 16px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 16px;
  color: #666666;
  line-height: 30px;
  text-align: center;
  font-style: normal;
  margin-bottom: 30px;
}
.margin-max-num {
  margin-bottom: 100px;
}
.eac-content {
  margin-top: 26px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 16px;
  color: #333333;
  line-height: 30px;
  text-align: justify;
  font-style: normal;
  padding-left: 18px;
}
.eac-content-model {
  margin-top: 10px;
  padding:0 15px 0 50px;
  margin-bottom: 26px;
}
.eac-content-model li {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 16px;
  color: #333333;
  line-height: 30px;
  text-align: justify;
  font-style: normal;
  text-indent: -10px;
}
.clear-text-indent li {
  text-indent: 0 !important;
}
.eac-sub-title {
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 24px;
  color: #333333;
  line-height: 34px;
  text-align: left;
  font-style: normal;
  height: 34px;
  margin-top: 50px;
  margin-bottom: 20px;
  cursor: pointer;
}
.eac-sub-title img {
  width: 22px;
  height: 12px;
  margin-right: 5px;
  
}
.eac-sub-title .none-show-pic {
  width: 12px;
  height: 22px;
  margin-right: 5px;
  margin-top: -2px;
}
.eac-table {
  height: 550px;
  margin-left: 20px;
}

</style>
