<template>
  <div class="partner">
    <div class="partner-content center">
      <h3>{{$t("clientsPartners")}}</h3>
      <div class="partner-content-pic">
        <ul class="partner-content-pic-top">
          <li>
            <a href="https://www.unep.org/"><img src="../../../assets/images/index/partner-pic2.png" alt="pic1"></a>
          </li>
          <li>
            <a href="https://www.ruc.edu.cn/"><img src="../../../assets/images/index/partner-pic3.png" alt="pic3"></a>
          </li>
          <li>
            <a href="https://www.zafu.edu.cn/"><img src="../../../assets/images/index/partner-pic8.png" alt="pic8"></a> 
          </li>
           <li>
            <a href="https://www.cecep.cn/"><img src="../../../assets/images/index/partner-pic4.png" alt="pic4"></a>
          </li>
           <li>
            <a href="http://www.caf.ac.cn/"><img src="../../../assets/images/index/partner-pic5.png" alt="china-energy"></a> 
          </li>
           <li>
            <a href="https://www.cgs.gov.cn/gzdt/zsdw/202310/t20231031_746432.html"><img src="../../../assets/images/index/partner-pic6.png" alt="china-energy"></a> 
          </li>
        
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "partner",
  data() {
    return {

    }
  }
}
</script>
<style>
.partner{
  width: 100%;
  height: 514px;
  background: #F3F7F8;
}
.partner-content{
  height: 100%;
  padding: 106px 0 0;
}
.partner-content h3{
  font-size: 34px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #1D1D1D;
  line-height: 34px;
}
.partner-content-pic-top ,.partner-content-pic-center ,.partner-content-pic-bottom {
  display: flex;
  flex-wrap: wrap;
}
.partner-content-pic-top {
  padding-top: 55px;
}
.partner-content-pic-top li{
  width: 292.5px;
  height: 108px;
  line-height: 108px;
  text-align: center;
}
/* .partner-content-pic-top li:nth-child(5n) , .partner-content-pic-top li:last-child{
  margin: 0;
} */
</style>