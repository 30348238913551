<template>
  <div class="introduce">
    <div class="introduce-content center">
      <!-- <h3>{{$t("whoWeAre")}}</h3> -->
      <p>{{$t("whoWeAreContent1")}}</p>
      <p>{{$t("whoWeAreContent2")}}</p>
    </div>
    <!-- <div class="introduce-content-img center " v-if="locale !== 'en-US' ">
      <img src="../../../assets/images/about/wnagzong.png" alt="碳排放证书" style="margin-right: 30px;margin-bottom: 30px">
      <img src="../../../assets/images/about/songbeibei1.png" alt="碳排放证书" style="margin-right: 30px;margin-bottom: 30px">
      <img src="../../../assets/images/about/hao.png" alt="碳排放证书" style="margin-bottom: 30px">
      <img src="../../../assets/images/about/chenchen.png" alt="碳排放证书" style="margin-right: 30px">
      <img src="../../../assets/images/about/changwu.png" alt="碳排放证书" style="margin-right: 30px">
    </div> -->
  </div>
</template>
<script>
export default {
  name: "introduce",
  data() {
    return {
      locale: ''
    }
  },
  created() {
    this.locale = localStorage.getItem('locale')
  }
}
</script>
<style>
.introduce {
  width: 100%;
  background: #FFFFFF;
  
}
.introduce-content {
  margin-top: 100px;
  padding: 24px 65px;
  /* height: 378px; */
  box-shadow: 0px 0px 4px 0px rgba(161, 208, 107, 0.80);
  border-radius: 30px;
}
.introduce-content-img {
  padding: 34px 0 0 ;
}
.introduce-content h3{
  font-size: 33px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #1D1D1D;
  line-height: 46px;
}
.introduce-content p{
  margin: 30px 0;
  font-size: 22px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 40px;
  /* text-indent:44px; */
}
#app.word-spacing .introduce{
  height: 564px;
}

</style>