<template>
  <div class="carbon">
    <div class="carbon-header">
      <public-header></public-header>
      <!-- 大标题 -->
      <div class="carbon-title">
        <p class="spacing6">{{$t("carbonTitle")}}</p>
      </div>
      <div class="scoll-icon">
        <img src="../../assets/images/index/scroll.png" alt="icon">
      </div>
    </div>
    <!-- 质量 -->
    <div class="carbon-quality center" :class="locale !== 'zh-CN' ? 'carbon-quality-en' : ''">
      <div class="carbon-subTitle">{{$t("carbonSubTitle1")}}</div>
      <ul>
        <li class="carbon-quality-bg">
          <div>
            <img src="../../assets/images/carbon/tree-icon.png" alt="icon">
            <span>{{$t("carbonQualityTitle1")}}</span>
          </div>
          <p>{{$t("carbonQualityContent1")}}</p>
        </li>
        <li>
          <div>
            <img src="../../assets/images/carbon/tree-icon.png" alt="icon">
            <span>{{$t("carbonQualityTitle2")}}</span>
          </div>
          <p>{{$t("carbonQualityContent2")}}</p>
        </li>
        <li class="carbon-quality-bg">
          <div>
            <img src="../../assets/images/carbon/tree-icon.png" alt="icon">
            <span>{{$t("carbonQualityTitle3")}}</span>
          </div>
          <p>{{$t("carbonQualityContent3")}}</p>
        </li>
      </ul>
      <ul>
        <li>
          <div>
            <img src="../../assets/images/carbon/tree-icon.png" alt="icon">
            <span>{{$t("carbonQualityTitle4")}}</span>
          </div>
          <p>{{$t("carbonQualityContent4")}}</p>
        </li>
        <li class="carbon-quality-bg">
          <div>
            <img src="../../assets/images/carbon/tree-icon.png" alt="icon">
            <span>{{$t("carbonQualityTitle5")}}</span>
          </div>
          <p>{{$t("carbonQualityContent5")}}</p>
        </li>
        <li>
          <div>
            <img src="../../assets/images/carbon/tree-icon.png" alt="icon">
            <span>{{$t("carbonQualityTitle6")}}</span>
          </div>
          <p>{{$t("carbonQualityContent6")}}</p>
          <p>{{$t("carbonQualityContent61")}}</p>
        </li>
      </ul>
    </div>
    <!-- 标准 -->
    <div class="carbon-standard center">
      <div class="carbon-subTitle">{{$t("carbonSubTitle2")}}</div>
      <ul>
        <li @click="standardShow('show1')">
          <div>
            <img src="../../assets/images/carbon/standard1.png" alt="icon">
          </div>
          <p v-show="status">
            {{$t("carbonStandardContent1")}}
          </p>
        </li>
        <li @click="standardShow('show2')">
          <div>
            <img src="../../assets/images/carbon/standard2.png" alt="icon">
          </div>
          <p v-show="status">
            {{$t("carbonStandardContent2")}}
          </p>
        </li>
        <li @click="standardShow('show3')">
          <div>
            <img src="../../assets/images/carbon/standard3.png" alt="icon">
          </div>
          <p v-show="status">
            {{$t("carbonStandardContent3")}}
          </p>
        </li>
      </ul>
    </div>
    <!-- 类型 -->
    <div class="carbon-type center">
      <div class="carbon-subTitle">{{$t("carbonSubTitle3")}}</div>
      <p class="carbon-content">
        {{$t("carbonContent1")}}
        <router-link :to="{path: '/project'}">
          {{$t("carbonContent2")}}
        </router-link>
        {{$t("carbonContent3")}}
      </p>
      <div class="carbon-box">{{$t("carbonBoxContent")}}</div>
      <ul class="carbon-project">
        <li>
          <img src="../../assets/images/carbon/type1.png" alt="icon">
          <div>
            <p>{{$t("carbonProjectItemTitle1")}}</p>
            <span>{{$t("carbonProjectItemContent1")}}</span>
          </div>
        </li>
        <li>
          <img src="../../assets/images/carbon/type2.png" alt="icon">
          <div>
            <p>{{$t("carbonProjectItemTitle2")}}</p>
            <span>{{$t("carbonProjectItemContent2")}}
              <router-link :to="{path: '/grassland-project'}">
                {{$t("carbonProjectItemContent21")}}
              </router-link>
              </span>
          </div>
        </li>
        <li>
          <img src="../../assets/images/carbon/type3.png" alt="icon">
          <div>
            <p>{{$t("carbonProjectItemTitle3")}}</p>
            <span>{{$t("carbonProjectItemContent3")}}</span>
          </div>
        </li>
      </ul>
    </div>
    <info />
    <public-footer></public-footer>
  </div>
</template>
<script>
import info from "../../components/info/index.vue"
export default {
  name: 'carbon',
  components: {
    info,
  },
  data() {
    return {
      locale: '',
      status: false,
      // standardStatus: {
      //   show1: false,
      //   show2: false,
      //   show3: false,
      // }
    }
  },
  created() {
    this.locale = localStorage.getItem('locale')
    this.locale = this.locale ? this.locale : 'zh-CN'
  },
  methods: {
    standardShow() {
      // this.standardStatus[str] = !this.standardStatus[str]
      this.status = !this.status
    }
  }
}
</script>
<style>
.carbon-header{
  width: 100%;
  min-width: 1200px;
  height: 750px;
  background-image: url(../../assets/images/carbon/banner-carbon.png);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.carbon-title{
  margin-top: 283px;
  display: flex;
  justify-content: center;
  font-size: 68px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  line-height: 68px;
}
.scoll-icon {
  position: absolute;
  bottom: 38px;
  left: 50%;
  margin-left: -11px;
}
.carbon-title p {
  color: #FFFFFF;
}
.carbon-quality {
  height: auto;
}
.carbon-subTitle {
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 30px;
  color: #333333;
  line-height: 42px;
  text-align: center;
  font-style: normal;
  margin: 100px auto 46px;
}
.carbon-quality ul, .carbon-standard ul{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 22px;
}
.carbon-quality li, .carbon-standard li {
  width: 381px;
  box-sizing: border-box;
  padding: 64px 34px 40px 34px;
  box-shadow: 0px 0px 4px 0px rgba(161, 208, 107, 0.80);
  border-radius: 30px;
  overflow: hidden;
}
.carbon-quality li div {
  margin-bottom: 10px;
}
.carbon-quality li img {
  width: 35px;
  height: 35px;
}
.carbon-quality li span {
  display: inline-block;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 16px;
  color: #333333;
  line-height: 36px;
  text-align: justify;
  font-style: normal;
  position: relative;
  top: 10px;

}
.carbon-quality-en li p {
  line-height: 20px;
}
.carbon-quality li p {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 16px;
  color: #333333;
  line-height: 30px;
  /* text-align: justify; */
  font-style: normal;
}
.carbon-quality .carbon-quality-bg {
  background-image: url();
  background-size: 105% 105%;
  background-position: -15px;
  background-repeat: no-repeat;
  background-image: url(../../assets/images/carbon/content-bg.png);
}
.carbon-quality .carbon-quality-bg span, .carbon-quality .carbon-quality-bg p {
  color: #fff;
}
.carbon-standard li {
  padding: 34px;
  cursor: pointer;
}
.carbon-standard li div {
  display: flex;
}
.carbon-standard img {
  width: auto;
  height: 40px;
  margin: 0 auto;
}
.carbon-standard p {
  margin: 20px 0;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 16px;
  color: #333333;
  line-height: 30px;
  /* text-align: justify; */
  font-style: normal;
}
.carbon-type {
  margin: 94px auto 96px ;
  padding: 54px;
  box-shadow: 0px 0px 4px 0px rgba(161, 208, 107, 0.80);
  border-radius: 30px;
}
.carbon-type .carbon-subTitle {
  margin: 0 0 50px 0;
}
.carbon-type .carbon-content {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 18px;
  color: #333333;
  line-height: 35px;
  text-align: left;
  font-style: normal;
}
.carbon-type .carbon-content a,.carbon-type .carbon-project a {
  color: #046030;
  text-decoration: revert;
}
.carbon-box {
  padding: 49px 54px 54px 54px;
  margin: 26px 0 46px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 18px;
  color: #FFFFFF;
  line-height: 35px;
  text-align: left;
  font-style: normal;
  background-image: url(../../assets/images/carbon/box-bg.png);
  background-size: 100% 100%;
}
.carbon-project li{
  margin-top: 30px;
  display: flex;
  flex-direction: row;
}
.carbon-project li img {
  width: 442px;
  height: 248px;
  margin-right: 20px;
}
.carbon-project li div {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
}
.carbon-project li div p {
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 24px;
  color: #333333;
  line-height: 33px;
  text-align: left;
  font-style: normal;
}
.carbon-project li div span {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 18px;
  color: #333333;
  line-height: 35px;
  text-align: left;
  font-style: normal;
  margin-top: 20px;
}
.carbon-project li:first-child {
  margin: 0
}
</style>
