<template>
  <div class="corporate">
    <div class="corporate-header">
      <public-header></public-header>
      <!-- 大标题 -->
      <div class="corporate-title">
        <p class="spacing6">{{$t("corporateTitle")}}</p>
      </div>
      <div class="scoll-icon">
        <img src="../../assets/images/index/scroll.png" alt="icon">
      </div>
    </div>
    <div class="corporate-subtitle center">
      <p>{{$t("corporateSubTitle1")}}</p>
      <p>{{$t("corporateSubTitle2")}}</p>
    </div>
    <div class="corporate-content center">
      <div class="content-item">
        <img src="../../assets/images/corporate/pic1.png" alt="icon">
        <div class="content-item-title">
          <p>{{$t("corporateItemTitle1")}}</p>
          <span>{{$t("corporateItemSubTitle1")}}</span>
        </div>
      </div>
      <div class="content-item">
        <img src="../../assets/images/corporate/pic2.png" alt="icon">
        <div class="content-item-title">
          <p>{{$t("corporateItemTitle2")}}</p>
        </div>
      </div>
      <div class="content-item" :class="locale === 'zh-CN' ? '' : 'content-item-en'">
        <img src="../../assets/images/corporate/pic3.png" alt="icon">
        <div class="content-item-title">
          <p>{{$t("corporateItemTitle3")}}</p>
          <div class="content-item-buttom" >
            <span @click="routerJump(1)">{{$t("corporateItemSpan1")}}</span>
            <span @click="routerJump(2)">{{$t("corporateItemSpan2")}}</span>
          </div>
        </div>
      </div>
      <div class="content-item">
        <img src="../../assets/images/corporate/pic4.png" alt="icon">
        <div class="content-item-title">
          <p>{{$t("corporateItemTitle4")}}</p>
          <span>{{$t("corporateItemSubTitle4")}}</span>
        </div>
      </div>
      <div class="case">
        <p>{{$t("corporateCase")}}</p>
        <div class="case-content" :class="locale === 'zh-CN' ? '' : 'case-content-en'">
          <img v-if="locale === 'zh-CN'" src="../../assets/images/corporate/case-pic.png" alt="icon">
          <img v-else src="../../assets/images/corporate/case-pic-en.png" alt="icon">
          <span>{{$t("corporateCOntent")}}</span>
        </div>
      </div>
    </div>
    <info />
    <public-footer></public-footer>
  </div>
</template>
<script>
import info from "../../components/info/index.vue"
export default {
  name: 'corporate',
  components: {
    info,
  },
  data() {
    return {
      locale: ''
    }
  },
  created() {
    this.locale = localStorage.getItem('locale')
    this.locale = this.locale ? this.locale : 'zh-CN'
  },
  methods: {
    routerJump(ev) {
      switch(ev) {
        case 1:
          this.$router.push('/green-certificate')
          break;
        case 2: 
          this.$router.push('/grassland-project')
          break;
      }
    }
  }
}
</script>
<style>
.corporate-header{
  width: 100%;
  min-width: 1200px;
  height: 750px;
  background-image: url(../../assets/images/corporate/banner.png);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.corporate-title{
  margin-top: 283px;
  display: flex;
  justify-content: center;
  font-size: 68px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  line-height: 68px;
}
.scoll-icon {
  position: absolute;
  bottom: 38px;
  left: 50%;
  margin-left: -11px;
}
.corporate-title p {
  color: #FFFFFF;
}
.corporate-subtitle {
  padding: 96px 0 46px;
}
.corporate-subtitle p {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 24px;
  color: #333333;
  line-height: 35px;
  text-align: center;
  font-style: normal;
}
.corporate-content {
  box-sizing: border-box;
  padding: 54px 50px 50px;
  box-shadow: 0px 0px 4px 0px rgba(161, 208, 107, 0.80);
  margin-bottom: 96px;
  border-radius: 30px;
}
.content-item {
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
  padding: 0 4px;
}
.content-item img {
  margin-right: 20px;
  width: 442px;
  height: 248px;
}
.content-item-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.content-item-title p {
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 24px;
  color: #333333;
  line-height: 33px;
  text-align: left;
  font-style: normal;
}
.content-item-title span {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 18px;
  color: #333333;
  line-height: 35px;
  text-align: left;
  font-style: normal;
  margin-top: 20px;
}
.content-item-title .content-item-buttom span {
  display: inline-block;
  height: 80px;
  box-shadow: 0px 0px 4px 0px rgba(161, 208, 107, 0.80);
  border-radius: 35px;
  font-weight: 600;
  font-size: 21px;
  color: #333333;
  line-height: 80px;
  cursor: pointer;
}
.content-item-title .content-item-buttom span:nth-child(1) {
  margin-right: 46px;
  padding: 0 54px;
}
.content-item-title .content-item-buttom span:last-child {
  padding: 0 44px;
}
.content-item-en {
  width: calc(100% + 32px);
}

.content-item-en .content-item-buttom span:nth-child(1) {
  margin-right: 12px;
  padding: 0 34px;
}
.content-item-en .content-item-title .content-item-buttom span:last-child {
  padding: 0 18px;
}
.case {
  margin-top: 50px;
}
.case p {
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 30px;
  color: #333333;
  line-height: 42px;
  text-align: center;
  font-style: normal;
  margin-bottom: 46px;
}
.case-content {
  position: relative;
  height: auto;
  display: flex;
  flex-direction: row;
  background-image: url(../../assets/images/corporate/case-bg.png);
  padding: 39px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.case-content-en {
  background-image: url(../../assets/images/corporate/case-bg-en.png);
}
.case-content img {
  width: 452px;
  height: 258px;
  margin-right: 25px;
}
.case-content-en img {
  height: 400px;
}
.case-content span {
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 18px;
  color: #FFFFFF;
  line-height: 35px;
  text-align: left;
  font-style: normal;
}
.case-content-en span {
  line-height: 30px;
}
</style>
