<template>
  <div class="asset-trad">
    <div class="asset-trad-header">
      <public-header></public-header>
      <!-- 大标题 -->
      <div class="asset-trad-title">
        <p class="spacing6">{{ $t("assettradTitle") }}</p>
      </div>
      <div class="scoll-icon">
        <img src="../../assets/images/index/scroll.png" alt="icon" />
      </div>
    </div>
    <div class="asset-trad-subtitle center">
      <p>{{ $t("assettradSubTitle1") }}</p>
      <p>{{ $t("assettradSubTitle2") }}</p>
    </div>
    <div class="asset-trad-map center">
      <img class="map"  src="../../assets/images/asset-trad/map.png" alt="map" />
      <ul v-if="locale === 'zh-CN'">
        <li>
          <img src="../../assets/images/asset-trad/tree-icon.png" alt="map" />
          <span>{{ $t("assettradMapItem1") }}</span>
        </li>
        <li>
          <img src="../../assets/images/asset-trad/tree-icon.png" alt="map" />
          <span>{{ $t("assettradMapItem2") }}</span>
        </li>
        <li>
          <img src="../../assets/images/asset-trad/tree-icon.png" alt="map" />
          <span>{{ $t("assettradMapItem3") }}</span>
        </li>
      </ul>
      <ul v-else class="asset-trad-en">
        <li>
          <img src="../../assets/images/asset-trad/tree-icon.png" alt="map" />
          <span>{{ $t("assettradMapItem1") }}</span>
        </li>
        <li>
          <img src="../../assets/images/asset-trad/tree-icon.png" alt="map" />
          <span>{{ $t("assettradMapItem2") }}</span>
        </li>
        <li>
          <img src="../../assets/images/asset-trad/tree-icon.png" alt="map" />
          <span>{{ $t("assettradMapItem3") }}</span>
        </li>
        <li>
          <img src="../../assets/images/asset-trad/tree-icon.png" alt="map" />
          <span>{{ $t("assettradMapItem4") }}</span>
        </li>
      </ul>
    </div>
    <div class="asset-trad-project center">
      <div class="project-item">
        <img src="../../assets/images/asset-trad/pic2.png" alt="project" />
        <div class="item-content">
          <p>{{ $t("assettradProjectItem1Title") }}</p>
          <span>{{ $t("assettradProjectItem1SubTitle") }}</span>
          <button @click="routerJump(1)">
            {{ $t("moreTitle") }}
          </button>
        </div>
      </div>
      <div class="project-item">
        <img src="../../assets/images/asset-trad/pic1.png" alt="project" />
        <div class="item-content">
          <p>{{ $t("assettradProjectItem2Title") }}</p>
          <span>{{ $t("assettradProjectItem2SubTitle") }}</span>
          <button @click="routerJump(2)">
            {{ $t("moreTitle") }}
          </button>
        </div>
      </div>
    </div>
    <info />
    <public-footer></public-footer>
  </div>
</template>
<script>
import info from "../../components/info/index.vue";
export default {
  name: "asset-trad",
  components: {
    info,
  },
  data() {
    return {
      locale: "",
    };
  },
  created() {
    this.locale = localStorage.getItem("locale");
    this.locale = this.locale ? this.locale : "zh-CN";
  },
  methods: {
    routerJump(ev) {
      switch(ev) {
        case 1:
          this.$router.push('/carbon')
          break;
        case 2:
          this.$router.push('/green-certificate')
          break;
      }
    }
  }
};
</script>
<style>
.asset-trad-header {
  width: 100%;
  min-width: 1200px;
  height: 750px;
  background-image: url(../../assets/images/asset-trad/banner.png);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.asset-trad-title {
  margin-top: 283px;
  display: flex;
  justify-content: center;
  font-size: 68px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  line-height: 68px;
}
.scoll-icon {
  position: absolute;
  bottom: 38px;
  left: 50%;
  margin-left: -11px;
}
.asset-trad-title p {
  color: #ffffff;
}
.asset-trad-subtitle {
  padding: 96px 0 46px;
}
.asset-trad-subtitle p {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 24px;
  color: #333333;
  line-height: 35px;
  text-align: center;
  font-style: normal;
}
.asset-trad-map {
  box-sizing: border-box;
  padding: 104px 110px;
  box-shadow: 0px 0px 4px 0px rgba(161, 208, 107, 0.8);
  border-radius: 30px;
  margin-bottom: 96px;
}
.asset-trad-map .map {
  height: 468px;
  margin-bottom: 46px;
  position: relative !important;
  top: 0 !important;
  left: 0 !important;
  width: auto !important;
  background: no-repeat !important;
}
.asset-trad-map ul {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.asset-trad-map .asset-trad-en {
  flex-wrap: wrap;
  justify-content: left;
}
.asset-trad-map ul li {
  margin-right: 40px;
}
.asset-trad-map ul li:last-child {
  margin: 0;
}
.asset-trad-map .asset-trad-en li {
  margin-bottom: 30px;
  margin-right: 0;
}
.asset-trad-map .asset-trad-en li:first-child {
  margin-right: 78px;
}
.asset-trad-map ul li img {
  width: 26px;
  height: 26px;
}
.asset-trad-map ul li span {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
  line-height: 26px;
  text-align: justify;
  font-style: normal;
  display: inline-block;
}
.asset-trad-project {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 96px;
}
.asset-trad-project .project-item {
  width: 550px;
  padding-bottom: 34px;
  padding-top: 0;
  box-shadow: 0px 0px 4px 0px rgba(161, 208, 107, 0.8);
  border-radius: 30px;
}
.asset-trad-project .project-item img {
  width: 100%;
  height: 415px;
}
.item-content {
  padding: 0 30px;
}
.asset-trad-project .project-item p {
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 18px;
  color: #333333;
  line-height: 25px;
  text-align: justify;
  font-style: normal;
  margin: 30px 0 13px;
}
.asset-trad-project .project-item span {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 16px;
  color: #333333;
  line-height: 30px;
  text-align: justify;
  font-style: normal;
}
.asset-trad-project .project-item button {
  display: block;
  width: 127px;
  height: 52px;
  font-size: 14px;
  color: #fff;
  line-height: 52px;
  text-align: center;
  font-style: normal;
  border-width: 0px;
  background: transparent;
  border-color: transparent;
  background-image: url(../../assets/images/index/new-button.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin-top: 30px;
  cursor: pointer;
}
</style>
