<template>
  <div class="center info">
    <div class="info-title">
      <p>{{ $t("moreUs1") }}</p>
      <p>{{ $t("moreUs2") }}</p>
    </div>
    <div class="form">
      <el-form label-width="0" ref="ruleForm" :model="formLabelAlign">
        <div class="row">
          <el-form-item prop="nickName">
            <el-input
              v-model="formLabelAlign.nickName"
              :placeholder="$t('infoName')"
            ></el-input>
          </el-form-item>
          <el-form-item prop="email">
            <el-input
              v-model="formLabelAlign.email"
              :placeholder="$t('infoEmail')"
            ></el-input>
          </el-form-item>
        </div>
        <el-form-item prop="companyName">
          <el-input
          class="clear-placeholder"
            v-model="formLabelAlign.companyName"
            :placeholder="$t('infoCompanyName')"
          ></el-input>
        </el-form-item>
        <el-form-item class="textarea" prop="leaveWord">
          <el-input
            type="textarea"
            v-model="formLabelAlign.leaveWord"
            :placeholder="$t('infoMeg')"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="submit">{{
            $t("infoSubmit")
          }}</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      formLabelAlign: {
        nickName: "",
        email: "",
        companyName: "",
        leaveWord: "",
      },
      locale: "",
   
    };
  },
  created() {
    this.locale = localStorage.getItem("locale");
  },
  methods: {
    submit() {
      const { nickName, email, leaveWord } = this.formLabelAlign
      console.log(nickName, email, leaveWord)
      if (nickName && email && leaveWord) {
        this.httpRequest()
      }
    },
    httpRequest() {
      const _this = this
      let xhr;
      xhr = new XMLHttpRequest();
      xhr.open(
        "post",
        "https://www.qyh-investment.com/prod-api/system/visitor/add",
        true
      );
      xhr.setRequestHeader("content-type", "application/json");
      xhr.send(JSON.stringify(this.formLabelAlign));
      xhr.onreadystatechange = function () {
        if (xhr.readyState == 4) {
          let reg = /^2\d{2}/;
          if (reg.test(xhr.status)) {
            _this.$refs.ruleForm.resetFields()
          }
        }
      };
    },
  },
};
</script>
<style>
.info {
  padding: 44px 50px 44px 54px;
  height: 658px;
  background-image: url(../../assets/images/about/info.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;
  margin-bottom: 96px;
}
.info-title {
  padding-top: 420px;
}
.info-title p {
  width: 400px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 48px;
  color: #ffffff;
  line-height: 67px;
  letter-spacing: 2px;
  text-align: left;
  font-style: normal;
  padding: 0;
}
.form {
  width: 584px;
}
.form .el-form-item,
.form .el-form-item__content,
.form .el-input,
.form .el-input__inner,
.form .el-textarea,
.form .el-textarea__inner {
  height: 85px;
  border-radius: 30px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 21px;
  color: #333;
  line-height: 29px;
  text-align: left;
  font-style: normal;
}
.form .textarea,
.form .el-textarea,
.form .el-textarea__inner {
  height: 219px;
}
.form .el-input__inner,
.form .el-textarea__inner {
  padding: 20px 34px;
}
.form .el-input__inner::placeholder,
.form .el-textarea__inner::placeholder {
  color: #333
}
.clear-placeholder .el-input__inner {
  color: #666!important
}
.el-form-item {
  margin-bottom: 36px;
}
.form .row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.form .row .el-form-item__content {
  width: 253px;
}
.form .el-form-item .el-button {
  height: 85px;
  width: 100%;
  border-radius: 30px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 21px;
  color: #333333;
  line-height: 29px;
  text-align: center;
  font-style: normal;
}
</style>