<template>
  <div class="project-carousel">
    <el-carousel :interval="3000" arrow="always">
      <el-carousel-item v-for="(item,index) in pageCarousel" :key="index">
       <img :src="item" alt="item">
      </el-carousel-item>
    </el-carousel>
  </div>
</template>
<script>
export default {
  name:"carousel",
  props:{
    pageSize:Number,
    name:String
  },
  data(){
    return {
        pageCarousel: {
          1:require(`../../../assets/images/project/NanZhao/1.png`)
        },
    }
  },
  created(){
    this.addPage();
  },
  methods:{
    addPage(){
      for(let i = 1; i <= this.pageSize ;i++){
        this.pageCarousel[i] = require(`../../../assets/images/project/${this.name}/${i}.png`);
      }
    }
  }
}
</script>
<style>
.project-detail-right ul .el-carousel__indicator {
  display: none;
}
.el-carousel__container {
  height: 200px;
}
.el-carousel__arrow , .el-carousel__arrow:hover{
  background:transparent;
}
.el-carousel__arrow--left,.el-carousel__arrow--left:hover {
  background-image: url(../../../assets/images/project/left.png);
  background-repeat: no-repeat;
  background-position: 10px;
}
.el-carousel__arrow--right,.el-carousel__arrow--right:hover {
  background-image: url(../../../assets/images/project/right.png);
  background-repeat: no-repeat;
  background-position: 10px;
}
.el-icon-arrow-left {
  display: none;
}
</style>