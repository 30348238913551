<template>
  <div class="project">
    <div class="project-content center">
      <div class="project-content-nav">
          <h3>{{$t("projectTitle")}}</h3>
          <router-link :to="{path: '/project'}">
            <span class="spacing5">{{$t("moreTitle")}}</span>
            <img src="../../../assets/images/index/arrow.png" alt="箭头">
          </router-link>
      </div>
      <div class="project-content-body">
        <div class="project-content-body-left">
          <router-link :to="{path:'/guazhou-project'}">
            <img style="width: 100%; height: 100%" src="../../../assets/images/index/img-index-carbon.png" alt="图片">
            <div class="project-content-body-left-title">
              <p>{{$t("projectClassItemProjectTitle1")}}</p>
              <span>{{$t("projectClassItemProjectTime1")}}</span>
            </div>
          </router-link>
          </div>
        <ul class="project-content-body-right">
          <li>
            <router-link :to="{path:'/conversionOfLoggedToProtectedForest-InnerMongolia'}">
              <img src="../../../assets/images/index/project-img1.png" alt="图片">
              <div class="project-content-body-left-title">
                <p>{{$t("projectItemTitle2")}}</p>
                <span>{{$t("projectItemyear2")}}</span>
              </div>
            </router-link>
          </li>
          <li>
            <router-link :to="{path:'/conversionOfLoggedToProtectedForest-Keyihe'}">
              <img src="../../../assets/images/index/project-img2.png" alt="图片">
              <div class="project-content-body-left-title">
                <p>{{$t("projectItemTitle3")}}</p>
                <span>{{$t("projectItemyear3")}}</span>
              </div>
            </router-link>
          </li>
          <li>
            <router-link :to="{path:'/afforestation-Fangcheng'}">
              <img src="../../../assets/images/index/project-img5.png" alt="图片">
              <div class="project-content-body-left-title">
                <p>{{$t("projectItemTitle6")}}</p>
                <span>{{$t("projectItemyear6")}}</span>
              </div>
            </router-link>
          </li>
          <li>
            <router-link :to="{path:'/forestFarmCarbonSinkProject-Jiangxi'}">
              <img src="../../../assets/images/index/img.png" alt="图片">
              <div class="project-content-body-left-title">
                <p>{{$t("projectItemTitle1")}}</p>
                <span>{{$t("projectItemyear1")}}</span>
              </div>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "project",
  data() {
    return {

    }
  }
}
</script>
<style>
.project{
  width: 100%;
  background: #FFFFFF;
}
.project-content{
  box-sizing: border-box;
  padding: 106px 0 120px 0 ;
  height: 100%; 
}
.project-content-nav {
  display: flex;
  justify-content: space-between;
  margin-bottom: 33px;
}
.project-content-nav h3{
  font-size: 33px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #1D1D1D;
}
.project-content-nav h3,.project-content-nav span ,.project-content-nav img {
  line-height: 46px;
}
.project-content-nav span {
  margin-right: 15px;
}
.project-content-body {
  display: flex;
  justify-content: space-between;
}
.project-content-body-left,.project-content-body-right{
  width: 570px;
  height: 582px;
  border-radius: 30px;
  overflow: hidden;
}
.project-content-body-right {
  display: flex;
  flex-wrap: wrap;
}
.project-content-body-left{
  position: relative;
}
.project-content-body-left a::before{
  width: 570px;
  height: 582px;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 5;
}
.project-content-body-right li {
  width: 270px;
  height: 276px;
  align-items: flex-start;
  margin-right:30px;
  margin-bottom:30px;
  position: relative;
  border-radius: 30px;
  overflow: hidden;
}
.project-content-body-right li a::before{
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 270px;
  height: 276px;
  background: rgba(0, 0, 0, 0.2);
  z-index: 5;
}
.project-content-body-right li:nth-child(2n){
  margin-right:0px;
}
.project-content-body-left-title {
  box-sizing: border-box;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: left;
  margin-bottom: 13px;
  z-index: 6;
}
.project-content-body-left-title p{
  font-size: 15px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 22px;
}
.project-content-body-left-title span{
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 24px;
}
.project-content-body-left .project-content-body-left-title{
  padding-left: 25px;
}
.project-content-body-right .project-content-body-left-title{
  padding-left: 7px;
}
.project-content-body-left .project-content-body-left-title p{
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 22px;
}
</style>